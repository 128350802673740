import React, { useState } from 'react';
import { Form, Button, Input, Spin, Modal, Alert } from 'antd';
import { useAuth } from '../contexts/authContext';
import api from '../utils/axios';

// --- Styles
const contentStyle = {
    margin: '50px auto',
    maxWidth: 720,
    padding: 50,
    background: 'white',
    borderRadius: 3,
};

const spinnerStyle = {
    display: 'flex',
    justifyContent: 'space-around',
};

const Login = () => {
    const { isLoading, signIn } = useAuth();
    const [modal1Open, setModal1Open] = useState(false);
    const [emailAd, setEmailAd] = useState('');
    const [button1Clicked, setButton1Clicked] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [error, setError] = useState('');
    const [passwordField1, setPasswordField1] = useState('');
    const [passwordField2, setPasswordField2] = useState('');
    const [button2Clicked, setButton2Clicked] = useState(false);
    const [verifCode, setVerifCode] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    const clearModal = () => {
        setModal1Open(false);
        setEmailAd('');
        setButton1Clicked(false);
        setCodeSent(false);
        setError('');
        setPasswordField1('');
        setPasswordField2('');
        setButton2Clicked(false);
        setVerifCode('');
    };

    const onLogin = async ({ email, password }: { email: string; password: string }) => {
        setSuccessMsg('');
        signIn(email, password);
    };

    return (
        <div style={contentStyle}>
            {isLoading ? (
                <div style={spinnerStyle}>
                    <Spin size="large" />
                </div>
            ) : (
                <div>
                    <h3>
                        Website is currently under construction; But the password reset feature is available if required
                    </h3>
                    {successMsg ? <Alert message={successMsg} type="success" /> : null}
                    <Form layout="vertical" onFinish={onLogin}>
                        <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[{ required: true, message: 'Please insert email' }]}>
                            <Input size="large" type="email" placeholder="yourname@transmarine.lk" />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please insert password' }]}>
                            <Input.Password size="large" type="password" />
                        </Form.Item>

                        <Form.Item style={{ marginTop: 48 }}>
                            <Button size="large" type="primary" htmlType="submit">
                                Login
                            </Button>
                        </Form.Item>
                    </Form>
                    <Button
                        onClick={() => {
                            setModal1Open(true);
                        }}>
                        Forgot password/Change password
                    </Button>
                    <Modal
                        visible={modal1Open}
                        footer={null}
                        onCancel={() => {
                            clearModal();
                        }}>
                        {error ? <Alert message={error} type="error" /> : null}
                        <p>Change password</p>
                        <p>
                            Please enter the email address you use to log in
                            <br />
                            This email address will receive a verification code that you will then need to enter here
                        </p>
                        <Input
                            placeholder="Email address"
                            disabled={codeSent || button1Clicked}
                            value={emailAd}
                            onChange={(event) => {
                                setEmailAd(event.target.value);
                            }}
                        />
                        <br />
                        <Button
                            disabled={codeSent || button1Clicked}
                            onClick={async () => {
                                setError('');
                                setButton1Clicked(true);
                                try {
                                    await api.post('/auth/resetPwStep1', { email: emailAd });
                                    setCodeSent(true);
                                } catch {
                                    setError('Please recheck your email address');
                                    setButton1Clicked(false);
                                }
                            }}>
                            Send verification code
                        </Button>
                        {codeSent ? (
                            <div>
                                <p>
                                    An email has been sent containing a verification code
                                    <br />
                                    Please enter it below
                                </p>
                                <Input
                                    placeholder="Verification code"
                                    type="number"
                                    disabled={button2Clicked}
                                    value={verifCode}
                                    onChange={(event) => {
                                        setVerifCode(event.target.value);
                                    }}
                                />
                                <br />
                                <p>(Please note that the code is only valid for 1 hour)</p>
                                <p>
                                    Please enter the new password you wish to change to
                                    <Input
                                        disabled={button2Clicked}
                                        placeholder="New password"
                                        value={passwordField1}
                                        type="password"
                                        onChange={(event) => {
                                            setPasswordField1(event.target.value);
                                        }}
                                    />
                                    <br />
                                    <Input
                                        disabled={button2Clicked}
                                        placeholder="Re-type new password"
                                        value={passwordField2}
                                        type="password"
                                        onChange={(event) => {
                                            setPasswordField2(event.target.value);
                                        }}
                                    />
                                    <br />
                                </p>
                                <Button
                                    disabled={button2Clicked}
                                    onClick={async () => {
                                        setError('');
                                        if (!passwordField1 || !passwordField2 || passwordField1 !== passwordField2) {
                                            setError('Please enter the same valid password in both fields');
                                        } else if (passwordField1.length < 8 || passwordField2.length < 8) {
                                            setError('Please enter a password of at least 8 characters');
                                        } else if (
                                            !verifCode ||
                                            verifCode.length < 4 ||
                                            Number.isNaN(parseInt(verifCode, 10))
                                        ) {
                                            setError('Please enter a valid verification code');
                                        } else {
                                            setButton2Clicked(true);
                                            try {
                                                await api.post('/auth/resetPwStep2', {
                                                    email: emailAd,
                                                    code: parseInt(verifCode, 10),
                                                    password: passwordField1,
                                                });
                                                setSuccessMsg('Password change successful');
                                                clearModal();
                                            } catch {
                                                setError('Please recheck your verification code');
                                                setButton2Clicked(false);
                                            }
                                        }
                                    }}>
                                    Change password
                                </Button>
                            </div>
                        ) : null}
                    </Modal>
                </div>
            )}
        </div>
    );
};

export default Login;
